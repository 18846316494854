<template>
  <b-card
    no-body
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      :data-toggle="collapsableItem ? 'collapse' : ''"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <h2 :class="amountClassProp + ' lead collapse-title'">{{ title }}</h2>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    amountClassProp: {
      type: String,
      required: true,
    },
    collapsableItem: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      if(this.collapsableItem){
          this.visible = val
          this.$emit('visible', val)
      }
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>
<style scoped>
[dir=ltr] [class*=collapse-] .card .card-header {
    padding-top: 30px;
    padding-right: 75px;
    padding-bottom: 30px;
    padding-left: 30px;
}

[dir] .collapse-icon [data-toggle=collapse]:after {
    margin-top: -15px !important;
    background-size: 20px !important;
}

.muted {
    color: #B9B9C3;
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 18px;
}
</style>