<template>
  <div>
    <section class="invoice-preview-wrapper">
      <div id="lottie" v-show="show">
        <div class="loading-logo">
          <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
        </div>
        <div class="loading">
          <div class="effect-1 effects" />
          <div class="effect-2 effects" />
          <div class="effect-3 effects" />
        </div>
      </div>
      <template>
        <div class="mt-1">
          <h2
            v-if="changeOrder.id"
            class="notprintable"
            style="font-weight: 600 !important; font-size: 21px!important; line-height: normal;!important"
          >
            {{
              changeOrder.opportunity.address + ", " + changeOrder.opportunity.geography
            }}
          </h2>
          <uil-suitcase class="logo" size="15px" />
          {{ changeOrder.opportunity ? changeOrder.opportunity.account_name : "" }}
          <span style="padding-left: 20px">
            <uil-tape class="logo" size="15px" />
            {{
              changeOrder.opportunity
                ? changeOrder.opportunity.rentable_sqft + " sqft"
                : 0
            }}
          </span>
          <span style="padding-left: 20px">
            <uil-bill class="logo" size="15px" />
            Change Order {{ changeOrder.internal_id }}
          </span>
        </div>
        <b-card class="mt-1" no-body style="max-height: 850px; height: 850px">
          <b-row style="margin-right: 0px">
            <b-col cols="9">
              <div style="padding-left: 30px">
                <p
                  style="
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 21px;
                    color: #636363;
                    padding-top: 40px;
                  "
                >
                  Change Order {{ changeOrder.change_order_number }} <br />
                </p>

                <p>
                  <b-badge pill :class="badgeClass(changeOrder.status)">
                    {{ changeOrder.status }}
                  </b-badge>
                </p>

                <p
                  style="
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #636363;
                    padding-top: 50px;
                  "
                >
                  Change Order Overview
                </p>
                <p
                  style="
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #6e6b7b;
                  "
                >
                  Reason for Change: <b>{{ changeOrder.type }}</b>
                </p>
                <p
                  style="
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #6e6b7b;
                    white-space: pre-line;
                  "
                >
                  {{ changeOrder.overview }}
                </p>
                <div style="padding-top: 45px">
                  <b-row
                    style="margin-bottom: 25px"
                    v-show="
                      changeOrder.change_order_lines &&
                      changeOrder.change_order_lines.length > 0
                    "
                  >
                    <b-col md="6" align-self="center">
                      <label
                        style="
                          font-weight: 600 !important;
                          font-size: 12px;
                          line-height: 23px;
                          color: #b9b9c3;
                          letter-spacing: 0.6px;
                          text-transform: uppercase;
                        "
                        >Quote Total</label
                      >
                      <h2
                        style="
                          color: #6e6b7b;
                          font-weight: 500 !important;
                          font-size: 24px;
                          line-height: 24px;
                        "
                      >
                        {{
                          (changeOrder.admin_fee_enabled
                            ? changeOrder.admin_fee_subtotal + changeOrder.subtotal
                            : changeOrder.subtotal
                          ).toLocaleString("en-CA", {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          })
                        }}
                      </h2>
                      <br />
                    </b-col>
                  </b-row>
                  <div>
                    <b-button
                      :href="
                        '/opportunities/' +
                        changeOrder.opportunity.sf_opportunity_id +
                        '/change-order/' +
                        changeOrder.id +
                        '/edit'
                      "
                      variant="primary"
                      v-if="
                        changeOrder.change_order_lines &&
                        changeOrder.change_order_lines.length == 0
                      "
                    >
                      Create CO Quote
                    </b-button>
                    <b-button
                      target="_blank"
                      variant="outline-primary"
                      v-else
                      :href="
                        '/opportunities/' +
                        changeOrder.opportunity.sf_opportunity_id +
                        '/change-order/' +
                        changeOrder.id
                      "
                    >
                      View Change Order
                    </b-button>
                    <!-- Dropdown -->
                    <b-dropdown
                      v-if="
                        isAdmin == 1 && changeOrder.opportunity.marked_as_complete == 0
                      "
                      no-caret
                      variant="link"
                    >
                      <template #button-content>
                        <feather-icon
                          class="align-middle text-body"
                          icon="MoreVerticalIcon"
                          size="16"
                        />
                      </template>

                      <b-dropdown-item target="_blank" @click="deleteCO()">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">Delete (Admins Only)</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col cols="3" style="padding-top: 30px"> </b-col>
          </b-row>
        </b-card>
      </template>
    </section>
  </div>
</template>

<script>
import {
  UilBill,
  UilBox,
  UilCheckCircle,
  UilEditAlt,
  UilExclamationTriangle,
  UilHardHat,
  UilPlus,
  UilRuler,
  UilSuitcase,
  UilTape,
  UilWifi,
  UilChartLine,
  UilImport,
  UilSync,
  UilArchive,
  UilMessage,
  UilCheck,
  UilArrowCircleLeft,
  UilFileMinusAlt,
} from "@iconscout/vue-unicons";
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  VBToggle,
  BNavItem,
  BBadge,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormTextarea,
  BDropdownDivider,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  BDropdownGroup,
  BDropdownHeader,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItemDPMOutput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    Logo,
    AppCollapse,
    AppCollapseItem,
    UilRuler,
    UilSuitcase,
    UilBill,
    UilTape,
    UilBox,
    UilWifi,
    UilHardHat,
    UilPlus,
    UilCheckCircle,
    UilEditAlt,
    UilExclamationTriangle,
    BNavItem,
    BBadge,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    UilChartLine,
    UilImport,
    UilSync,
    BFormGroup,
    BFormTextarea,
    UilArchive,
    BDropdownDivider,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    UilMessage,
    UilCheck,
    UilArrowCircleLeft,
    UilFileMinusAlt,
    BDropdownGroup,
    BDropdownHeader,
  },
  data() {
    return {
      opportunityId: this.$route.params.ActiveProjectId,
      changeOrderId: this.$route.params.co_id,
      changeOrder: {},
      changeOrderLines: {},
      quote: {
        opportunity: {},
        comments: {},
      },
      comments: {},
      options: {},
      siteConditions: {},
      show: true,
      newComment: "",
      packageApproved: false,
      modalIsBusy: false,
      isAdmin: false,
    };
  },
  beforeMount() {
    this.getChangeOrder();
    this.isAdmin = localStorage.getItem("isAdmin");
  },

  methods: {
    getChangeOrder() {
      this.$http
        .get(`/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}`)
        .then((response) => {
          this.changeOrder = response.data.data;
          this.changeOrderLines = this.changeOrder.change_order_lines;
          this.show = false;
          if (this.changeOrder.change_order_number) {
            document.title = `[${this.changeOrder.change_order_number}] View Change Order`;
          }
          this.updateBreadcrumb();
        })
        .catch((error) => {
          this.showToast("danger", error);
        });
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].to = `/active`;
      this.$route.meta.breadcrumb[0].active = true;
      this.$route.meta.breadcrumb[0].text = `Active Project`;
      this.$route.meta.breadcrumb[1].text = `${this.changeOrder.opportunity.address}, ${this.changeOrder.opportunity.geography}`;

      this.$route.meta.breadcrumb[1].to = `/active/${this.$route.params.ActiveProjectId}`;
      this.$route.meta.breadcrumb[1].active = false;
      this.$route.meta.breadcrumb[2].text = `Revisions`;

      this.$route.meta.breadcrumb[2].active = true;
      this.$route.meta.breadcrumb[3].text = `Change Order ${this.changeOrder.change_order_number}`;

      this.$route.meta.breadcrumb[3].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    badgeClass(status) {
      if (status == "Draft") {
        return "draft";
      } else if (status == "Approved") {
        return "approved";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Quote In Progress") {
        return "quote-in-progress";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      }
    },
    deleteCO() {
      this.$http
        .delete(
          `/opportunities/${this.changeOrder.opportunity.sf_opportunity_id}/change-orders/${this.changeOrder.id}`
        )
        .then((response) => {
          this.showToast(
            "success",
            `Change Order ${this.changeOrder.id} has been successfully deleted.`,
            "Change Order Successfully"
          );
          this.$router.push("/active/" + this.$route.params.ActiveProjectId);
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "Error Deleting CO",
            "Unable to delete CO at this time."
          );
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
